<template>
    <static-message header="You have successfully been added to our newsletter."
        caption="We look forward to providing you restored and redefined antiques!" />
</template>

<script>
    import StaticMessage from './StaticMessage.vue';

    export default {
        components: {
            StaticMessage
        }
    }
</script>