import fetch_controller from "./fetch_controller.js";

class Inquiry{
    constructor(name = "", email = "", message = ""){
        this.name = name;
        this.email = email;
        this.messages = [];
        this.emailHandler = new FormData();
        this.attachmentAmount = 0;
        this.statusComplete = false;

        if(message != "")
            this.messages.push(message);
    }

    submit(){
        let doesSubmit = this.name.length > 0 && this.email.includes('@') && this.messages.length > 0;

        if(doesSubmit){
            let text = `The following text was received by ${this.name}.\n`
                + `You can reach them at ${this.email}.\n\n`;
            
            for(let msg of this.messages)
                text += msg + "\n";
            text += "\n";

            text += "This was an automated email generated by rusticredefined.ca. Please respond"
                + " directly to the email provided at the beginning of this message.";
            
            fetch_controller.postJson('/store_inquiry_contact', {
                text: text
            }, data => {
                fetch("https://rusticandredefined.ca:8081/send_inquiry_email", {
                    method: 'POST',
                    body: this.emailHandler
                })
                .then(res => {
                    if(!res.ok)
                        throw 'Server error at request.';
                    this.statusComplete = true;
                })
                .catch(err => { if(err) console.log("Error sending email."); });
            });
        }
        return doesSubmit;
    }

    addMessage(msg){
        this.messages.push(msg);
    }

    addAttachment(file){
        ++this.attachmentAmount;
        this.emailHandler.append("images", file);
    }

    getData(){
        return {
            name: this.name,
            email: this.email,
            messages: this.messages
        };
    }

    isStatusComplete(){
        return this.statusComplete;
    }
}

export default Inquiry;