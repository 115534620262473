<template>
    <static-message header="You have successfully unsubscribed from our newsletter." />
</template>

<script>
    import StaticMessage from './StaticMessage.vue';

    export default {
        components: {
            StaticMessage
        }
    }
</script>