<template>
    <div class="page-content fixed-navbar-support">
        <navbar position="fixed" :admin-logged="adminLogged" /><br />

        <div id="dashboard" v-if="loginState == 0 || loginState === 1">
            <class-anim target-class="wait-msg" alternate-classes="transition" ref="wait-msg">
                <h1>Please Wait&#8230;</h1>
            </class-anim>

            <div id="taskbar">
                <button @click="redirectHome"><h1>Home</h1></button>
            </div>

            <!--Marketplace partners-->
            <div class="expand-me">
                <div class="banner" @click="() => this.$refs['marketplace-inner'].toggle()">
                    <h1>Marketplace Partners</h1>
                    <span class="banner-btn">Expand/Close</span>
                </div>
                <class-anim target-class="expand-me-inner" alternate-classes="marketplace-inner" ref="marketplace-inner">
                    <div id="marketplace-add-partner">
                        <label for="name">Name</label>
                        <input type="text" ref="marketplace-name" />
                        <label for="link">Link</label>
                        <input type="text" ref="marketplace-link" @keypress="requestAddToMarketplace" />
                        <button @click="addToMarketplace">Submit</button><br />
                    </div>
                    <div>
                        <h4 v-if="marketplace.names.length > 0">Partners</h4><br />
                        <ul>
                            <li v-for="(partner, index) of marketplace.names">
                                {{ partner }} (<a :href="marketplace.links[index]"
                                target="_blank">{{ marketplace.links[index] }}</a>)
                                <button @click="() => deleteFromMarketplace(index)">X</button>
                            </li>
                        </ul>
                    </div>
                </class-anim>
            </div>

            <!--Newsletter-->
            <div class="expand-me">
                <div class="banner" @click="() => this.$refs['newsletter-inner'].toggle()">
                    <h1>Newsletter</h1>
                    <span class="banner-btn">Expand/Close</span>
                </div>
                <class-anim target-class="expand-me-inner" alternate-classes="newsletter-inner" ref="newsletter-inner">
                    <div id="newsletter-subscribers-container">
                        <h3>Subscriber List</h3>
                        <textarea ref="newsletter-subscribers" readonly></textarea>
                    </div>
                    <div id="newsletter-station">
                        <h3>Send Out A Newsletter</h3>
                        <b id="newsletter-subject-label">Subject</b>
                        <textarea id="newsletter-subject" ref="newsletter-subject"></textarea>
                        <button id="attach-to-newsletter" @click="attachToNewsletter">
                            <image-loader path="icon/capture.png" alt-value="Attach" />
                        </button>
                        <div id="newsletter-body" ref="newsletter-body" @click="() => this.$refs['text-division-0'].focus()"
                        contenteditable="true">
                            <div class="text-division" id="text-division-0" ref="text-division-0"
                                contenteditable="contenteditable"></div>
                        </div>
                        <button id="newsletter-send" @click="sendNewsletter">Send</button>
                    </div>
                </class-anim>
            </div>

            <!--Shop & Projects-->
            <div class="expand-me">
                <div class="banner" @click="() => this.$refs['shop-inner'].toggle()">
                    <h1>Shop &#38; Projects {{ productEditMode ? ' (Add Item)' : '' }}</h1>
                    <span class="banner-btn">Expand/Close</span>
                </div>
                <class-anim target-class="expand-me-inner" alternate-classes="shop-inner" ref="shop-inner">
                    <div class="product-info">
                        <label for="product-category">Type</label>
                        <select ref="item-category-select" @change="selectItemCategory">
                            <option v-for="(itemCategory, index) of itemCategories"
                            :selected="itemCategoryIndexSelected == index"
                            :value="itemCategoryKeys[index]" :key="index">{{ itemCategory }}</option>
                        </select><br />
                        <div class="product-readable">
                            <label for="product-name">Name</label>
                            <input type="text" ref="product-name" /><br />
                            <div id="product-readable-price" v-if="itemCategoryIndexSelected != 0">
                                <label for="product-price">Price $</label>
                                <input type="number" ref="product-price" id="product-price" /><br />
                            </div>
                            <label for="product-about">About</label>
                            <textarea ref="product-about"></textarea><br />
                            <div id="product-metrics" v-if="itemCategoryIndexSelected != 0">
                                <label for="product-weight">Weight (lbs):</label>
                                <input type="text" ref="product-weight" id="product-weight" class="product-measurement" />
                                <label for="product-disclaimer">
                                    <small style="font-size: 10pt">Length+2*(Width+Height) must be less or equal than 165</small>
                                </label><br />
                                <label for="product-width">Inches: Width</label>
                                <input type="text" ref="product-width" id="product-width" class="product-measurement" />
                                <label for="product-height">Height</label>
                                <input type="text" ref="product-height" id="product-height" class="product-measurement" />
                                <label for="product-length">Length</label>
                                <input type="text" ref="product-length" id="product-length" class="product-measurement" />
                            </div>
                        </div>
                    </div>
                    <div class="product-display">
                        <hover-class-anim target-class="large-display-container" ref="display-0">
                            <parallax class="large-display display" ref="display-inner-0" :shield-opacity="dsOpacity1">
                                <class-anim target-class="thumbnail-context" alternate-classes="transition"
                                ref="thumbnail-context-0">
                                    <div>Drag image here to upload</div><br />
                                    <div>Thumbnail ({{ this.editModeBefore ? 'Before' : 'After' }})</div>
                                </class-anim>
                            </parallax>
                        </hover-class-anim>
                        <hover-class-anim target-class="small-display-container" ref="display-1">
                            <parallax class="small-display display" ref="display-inner-1" :shield-opacity="dsOpacity2">
                                <class-anim target-class="thumbnail-context" alternate-classes="transition"
                                ref="thumbnail-context-1">
                                    <div>1</div>
                                </class-anim>
                            </parallax>
                        </hover-class-anim>
                        <hover-class-anim target-class="small-display-container" ref="display-2">
                            <parallax class="small-display display" ref="display-inner-2" :shield-opacity="dsOpacity3">
                                <class-anim target-class="thumbnail-context" alternate-classes="transition"
                                ref="thumbnail-context-2">
                                    <div>2</div>
                                </class-anim>
                            </parallax>
                        </hover-class-anim>
                        <hover-class-anim target-class="small-display-container" ref="display-3">
                            <parallax class="small-display display" ref="display-inner-3" :shield-opacity="dsOpacity4">
                                <class-anim target-class="thumbnail-context" alternate-classes="transition"
                                ref="thumbnail-context-3">
                                    <div>3</div>
                                </class-anim>
                            </parallax>
                        </hover-class-anim>
                        <h3>Capacity({{ editModeBefore ? "before" : "after" }} images): {{
                            editModeBefore ? displayImages.beforeURL.length : displayImages.afterURL.length}}, Max: 4</h3>
                    </div>
                    <div class="product-options">
                        <input type="radio" value="before" name="display-mode"
                        @click="() => this.setEditModeBefore(true)" />
                        <label for="before">Before</label><br />
                        <input type="radio" value="after" name="display-mode" checked="checked"
                        @click="() => this.setEditModeBefore(false)" />
                        <label for="after">After</label><br />
                        <input type="submit" value="Clear" id="product-options-clear" @click="clearProduct" /><br />
                        <input type="submit" :value="productEditMode ? 'Update' : 'Submit'"
                            id="product-options-submit" @click="submitProduct" />
                    </div>
                    <div id="briefcase">
                        <div id="briefcase-label">Manage Product Categories (Add/Delete/View)</div>
                        <div id="item-categories">
                            <div class="item-category-container" v-for="(itemCategory, index) of itemCategories">
                                <div class="item-category" @click="() => visitItemCategory(index)">
                                    <h2>{{ itemCategory }}</h2>
                                </div>
                                <button class="item-category-exit" v-if="index > 0"
                                    @click="() => deleteItemCategory(index)">X</button>
                            </div>
                        </div>
                        <input type="text" id="item-category-new" ref="item-category-new"
                            @keypress="ev => { if(ev.keyCode == 13) addItemCategory(); }" />
                        <input type="submit" value="Add" id="item-category-submit" @click="addItemCategory" />
                    </div>
                </class-anim>
            </div>
        </div>
    </div>
</template>

<script>
    import '@/assets/css/dashboard.css';
    import Algorithm from '@/assets/js/Algorithm.js';
    import ClassAnim from '../../anim/class/ClassAnim.vue';
    import HoverClassAnim from '../../anim/class/HoverClassAnim.vue';
    import ImageLoader from '../../image/ImageLoader.vue';
    import Navbar from '@/components/layouts/nav/Navbar.vue';
    import EncryptedPage from '@/components/layouts/pages/EncryptedPage.vue';
    import Parallax from '../../image/Parallax.vue';
    import PORTS from '@/assets/js/PORTS.js';
    import fetch_controller from '@/assets/js/fetch_controller.js';
    import wait from '@/assets/js/wait.js';

    export default {
        extends: EncryptedPage,

        components: {
            ClassAnim,
            HoverClassAnim,
            ImageLoader,
            Navbar,
            Parallax
        },

        data(){
            return {
                dashboardInterval: undefined,
                displaysHighlighted: false,
                displayImages: {
                    data: new FormData(),
                    beforeInputs: [],
                    beforeURL: [],
                    afterInputs: [],
                    afterURL: []
                },
                dsOpacity1: 1,
                dsOpacity2: 1,
                dsOpacity3: 1,
                dsOpacity4: 1,
                loginState: 0,
                productEditMode: false,
                editModeBefore: false,
                itemCategoryIndexSelected: 0,
                itemCategories: [],
                itemCategoryKeys: [],
                newsletter: {
                    imageFiles: [],
                    imagesPerLane: []
                },
                marketplace: {
                    names: [],
                    links: []
                },
                adminLogged: false
            }
        },

        methods: {
            toggleThumbnailContext(index){
                this.$refs[`thumbnail-context-${index}`].toggle();

                //attributes do not update on lists, so I separated the variables
                let notBlack = this.$refs[`thumbnail-context-${index}`].isToggled();
                switch(index){
                    case 0:
                        this.dsOpacity1 = notBlack ? 0 : 1;
                        break;
                    case 1:
                        this.dsOpacity2 = notBlack ? 0 : 1;
                        break;
                    case 2:
                        this.dsOpacity3 = notBlack ? 0 : 1;
                        break;
                    case 3:
                        this.dsOpacity4 = notBlack ? 0 : 1;
                        break;
                }
            },

            getFileInput(){
                let inp = document.createElement("input");
                inp.type = "file";
                inp.accept = "image/*";
                inp.multiple = true;
                return inp;
            },

            insertImages(files){
                let inp = this.getFileInput();
                inp.files = files;

                let capacityFullMsg = str =>
                    window.alert("Capacity for the " + str + " images are at full (Too many images).");

                if(this.editModeBefore){
                    if(this.displayImages.beforeURL.length + files.length > 4){
                        capacityFullMsg("before");
                        return;
                    }
                    this.displayImages.beforeInputs.push(inp);
                } else {
                    if(this.displayImages.afterURL.length + files.length > 4){
                        capacityFullMsg("after");
                        return;
                    }
                    this.displayImages.afterInputs.push(inp);
                }

                for(let file of files){
                    if(file.type.match('image.*')){
                        var fr = new FileReader();
                        fr.onload = ev => {
                            let len;
                            if(this.editModeBefore){
                                this.displayImages.beforeURL.push(ev.target.result);
                                len = this.displayImages.beforeURL.length;
                            } else {
                                this.displayImages.afterURL.push(ev.target.result);
                                len = this.displayImages.afterURL.length;
                            }

                            this.$refs[`display-inner-${len - 1}`].shovePath(ev.target.result);
                            this.toggleThumbnailContext(len - 1);
                        };
                        fr.readAsDataURL(file);
                    }
                }
            },

            setEditModeBefore(bool){
                let getDi = index => this.$refs[`display-inner-${index}`],
                    getTc = index => this.$refs[`thumbnail-context-${index}`];
                let runIterations = urls => {
                    for(let i = 0; i < 4; i++){
                        if(i < urls.length){
                            getDi(i).shovePath(urls[i]);
                            if(!getTc(i).isToggled())
                                this.toggleThumbnailContext(i);
                        } else {
                            getDi(i).shovePath(undefined);
                            if(getTc(i).isToggled())
                                this.toggleThumbnailContext(i);
                        }
                    }
                };

                if(bool != this.editModeBefore){
                    runIterations(bool ? this.displayImages.beforeURL : this.displayImages.afterURL);
                    this.editModeBefore = bool;
                }
            },

            getShieldOpacityAttr(i){
                console.log("Testing shield opacity at index " + i);
                if(editModeBefore)
                    return this.displayImages.before.length > i ? '0' : '1';
                else
                    return this.displayImages.after.length > i ? '0' : '1';
            },

            dropImage(event){
                event.preventDefault();
                this.insertImages(event.dataTransfer.files);
            },

            dropImageWindow(event){
                event.preventDefault();
            },

            clearProduct(ev){
                this.$refs['product-name'].value = '';
                this.$refs['product-price'].value = '';
                this.$refs['product-about'].value = '';

                this.displayImages.before = [];
                this.displayImages.beforeURL = [];
                this.displayImages.after = [];
                this.displayImages.afterURL = [];
                for(let i = 0; i < 4; i++){
                    this.$refs[`display-inner-${i}`].overrideProcessedPath(undefined);
                    if(this.$refs[`thumbnail-context-${i}`].isToggled())
                        this.$refs[`thumbnail-context-${i}`].toggle();
                }

                this.dsOpacity1 = 1;
                this.dsOpacity2 = 1;
                this.dsOpacity3 = 1;
                this.dsOpacity4 = 1;
            },

            submitProduct(ev){
                ev.preventDefault();
                if(this.itemCategoryIndexSelected > 0 && (this.$refs['product-name'].value == ''
                || this.$refs['product-price'].value == '' || this.$refs['product-about'].value == ''
                || this.$refs['product-width'].value == '' || this.$refs['product-height'].value == ''
                || this.$refs['product-length'].value == '' || this.$refs['product-weight'].value == ''))
                    window.alert("All informative values must be filled for the item.");
                else if(this.itemCategoryIndexSelected == 0 && this.$refs['product-about'].value != ''
                    && this.$refs['product-name'].value == '')
                    window.alert("Your project must be labelled with a name IF it has a description.");
                else if(!this.displayImages.afterURL[0])
                    window.alert("There must be at least one image in the after department to showcase your item.");
                else {
                    this.$refs['wait-msg'].toggle();

                    let itemCategoryKey = this.itemCategoryKeys[this.itemCategoryIndexSelected];
                    let itemInfo = {
                        name: this.$refs['product-name'].value,
                        about: this.$refs['product-about'].value,
                    };
                    let indexAt = -1;
                    if(this.$refs['product-price']){
                        itemInfo['price'] = this.$refs['product-price'].value;
                        itemInfo['weight'] = Number(this.$refs['product-weight'].value);
                        itemInfo['width'] = Number(this.$refs['product-width'].value);
                        itemInfo['height'] = Number(this.$refs['product-height'].value);
                        itemInfo['length'] = Number(this.$refs['product-length'].value);
                        
                        if(isNaN(itemInfo['weight']) || isNaN(itemInfo['width'])
                            || isNaN(itemInfo['height']) || isNaN(itemInfo['length'])){
                            window.alert("Please make sure the product measurements are correct.");
                            return;
                        }
                    } 
                    if(localStorage.getItem('edit-item-place-at'))
                        indexAt = localStorage.getItem('edit-item-place-at');
                    console.log("indexAt: " + indexAt);

                    let selectIndex = undefined;
                    let ei = localStorage.getItem('edit-item-category-index');
                    if(ei)
                        selectIndex = Number(ei);

                    console.log("Posting item with selectIndex: " + selectIndex);
                    fetch_controller.postJson('/item/post', {
                        itemCategoryKey: itemCategoryKey,
                        itemInfo: itemInfo,
                        indexAt: indexAt,
                        beforeLength: this.displayImages.beforeURL.length,
                        selectIndex: selectIndex
                    }, data => {
                        let processBlob = (url) => {
                            var byteString = atob(url.split(',')[1]);
                            var mimeString = url.split(',')[0].split(':')[1].split(';')[0];
                            var ab = new ArrayBuffer(byteString.length);
                            var ia = new Uint8Array(ab);
                            for (var i = 0; i < byteString.length; i++)
                                ia[i] = byteString.charCodeAt(i);

                            return new Blob([ab], {type: mimeString});
                        };

                        for(let url of this.displayImages.beforeURL)
                            this.displayImages.data.append("images", processBlob(url));
                        for(let url of this.displayImages.afterURL)
                            this.displayImages.data.append("images", processBlob(url));

                        fetch(`${PORTS.SERVER}/item/post_images`, {
                            method: "POST",
                            body: this.displayImages.data
                        }).then(res => {
                            localStorage.setItem('admin-edit-mode', 'true');
                            if(this.itemCategoryIndexSelected > 0)
                                localStorage.setItem('edit-item-category-key', itemCategoryKey + '');
                            window.location.href = `${PORTS.CLIENT}/${this.itemCategoryIndexSelected > 0 ? "shop" : "projects"}`;
                        }).catch(err => {
                            if(err){
                                console.error(err);
                                window.alert("An error has occurred.");
                            }
                        });
                    });
                }
            },

            getSubscribersList(){
                fetch(`${PORTS.SERVER}/newsletter/subscriber_list`)
                .then(res => {
                    if(!res.ok){
                        console.error("An error has occured.");
                        return;
                    }

                    return res.json();
                }).then(data => {
                    this.$refs['newsletter-subscribers'].value = "";
                    for(let subscriber of data.subscribers)
                        this.$refs['newsletter-subscribers'].value += subscriber + "\n";
                }).catch(err => { if(err) console.error(err); })
            },

            selectItemCategory(){
                this.itemCategoryIndexSelected = this.$refs['item-category-select'].selectedIndex;
            },

            getItemCategoriesList(){
                fetch(`${PORTS.SERVER}/item/categories_and_keys`)
                .then(res => {
                    if(!res.ok){
                        console.error("An error has occurred.");
                        return;
                    }

                    return res.json();
                }).then(data => {
                    this.itemCategories = data.itemCategories;
                    this.itemCategoryKeys = data.itemCategoryKeys;

                    //check if in product edit mode, update dashboard
                    /*
                    this.productEditMode = localStorage.getItem('product-edit-mode');
                    if(this.productEditMode){
                        let editItemCategoryKey = Number(localStorage.getItem('edit-item-category-key'));
                        for(let i = 0; i < this.itemCategoryKeys.length; i++){
                            if(this.itemCategoryKeys[i] == editItemCategoryKey){
                                this.itemCategoryIndexSelected = i;
                                break;
                            }
                        }

                        this.$refs['shop-inner'].toggle();
                    }
                    */
                });
            },

            sendNewsletter(){
                let field1 = this.$refs['newsletter-subject'], field2 = this.$refs['newsletter-body'];
                if(field1.value.length > 0 && field2.textContent.length > 0){
                    let textDivs = [];
                    for(let textDiv of document.getElementsByClassName("text-division"))
                        textDivs.push(textDiv.textContent);
                    
                    fetch(`${PORTS.SERVER}/newsletter/send_text`, PORTS.postOptions({
                        textDivs: textDivs,
                        imagesPerLane: this.newsletter.imagesPerLane,
                        subject: document.getElementById('newsletter-subject').value
                    })).then(res => {
                        let fd = new FormData();
                        for(let file of this.newsletter.imageFiles)
                            fd.append("images", file);
                        fetch(`${PORTS.SERVER}/newsletter/send_images`, {
                            method: "POST",
                            body: fd
                        }).then(res => {
                            if(!res.ok){
                                window.alert("An error has occured.");
                                return;
                            }

                            window.alert("Message sent to subscriber list.");
                            this.$refs['newsletter-subject'].value = "";
                            this.$refs['newsletter-body'].textContent = "";
                        });
                    }).catch(err => {
                        if(err){
                            console.error(err);
                            window.alert("An error occurred. Please ensure you are connected to WiFi.");
                        }
                    });
                } else
                    window.alert("Make sure all required fields are filled before sending.");
            },

            addItemCategory(){
                let ncategory = this.$refs['item-category-new'].value;

                if(ncategory.length > 0){
                    this.$refs['item-category-new'].value = '';
                    this.itemCategories.push(ncategory);
                    fetch(`${PORTS.SERVER}/item/add_category`, PORTS.postOptions({ newCategory: ncategory }))
                    .then(res => res.json())
                    .then(data => {
                        this.itemCategoryKeys.push(data.itemCategoryKey);
                        this.itemCategoryIndexSelected = this.itemCategoryKeys.length - 1;
                    }).catch(err => {
                        if(err){
                            this.itemCategories.pop();
                            window.alert("An error has occured.");
                        }
                    });
                }
            },

            deleteItemCategory(index){
                this.itemCategories = new Algorithm.DeleteAlgorithm(this.itemCategories).getProduct(index);
                this.itemCategoryKeys = new Algorithm.DeleteAlgorithm(this.itemCategoryKeys).getProduct(index);
                fetch(`${PORTS.SERVER}/item/delete_category`, PORTS.postOptions({ index: index }));
            },

            visitItemCategory(index){
                localStorage.setItem('admin-edit-mode', 'true');
                fetch(`${PORTS.SERVER}/item/visit_category`, PORTS.postOptions({ index: index }))
                .then(res => {
                    if(!res.ok){
                        window.alert("An error occurred");
                        return;
                    }

                    return res.json();
                }).then(data => {
                    if(index > 0)
                        localStorage.setItem('edit-item-category-key', data.itemCategoryKey + '');
                    window.location.href = `${PORTS.CLIENT}/${index != 0 ? "shop" : "projects"}`;
                });
            },

            attachToNewsletter(){
                //this.newsletterPlainText = document.getElementById("newsletter-body").textContent;
                let newsBody = document.getElementById("newsletter-body");
                let fileInp = this.getFileInput();
                fileInp.addEventListener("change", ev => {
                    this.newsletter.imagesPerLane.push(0);
                    for(let file of ev.target.files){
                        this.newsletter.imageFiles.push(file);
                        this.newsletter.imagesPerLane[this.newsletter.imagesPerLane.length - 1]++;
                        
                        let fr = new FileReader();
                        fr.onload = ev => {
                            let subsectionImg = document.createElement("img");
                            subsectionImg.src = ev.target.result;
                            subsectionImg.alt = "Newsletter item";
                            subsectionImg.style.width = "225pt";
                            newsBody.appendChild(subsectionImg);

                            let textDivision = document.createElement("div");
                            textDivision.className = "text-division";
                            textDivision.id = "text-division-1";
                            textDivision.setAttribute("contenteditable", "contenteditable");
                            textDivision.textContent = "";
                            newsBody.appendChild(textDivision);
                        };

                        fr.readAsDataURL(file);
                    }
                });
                fileInp.click();
            },

            addToMarketplace(){
                let name = this.$refs['marketplace-name'].value, link = this.$refs['marketplace-link'].value;
                if(!link.includes('https://') && !link.includes('http://'))
                    link = 'https://' + (!link.includes('www.') ? 'www.' : '') + link;

                fetch(`${PORTS.SERVER}/marketplace/add_partner`, PORTS.postOptions({
                    name: name,
                    link: link
                })).then(res => {
                    this.marketplace.names.push(name);
                    this.marketplace.links.push(link);
                    this.$refs['marketplace-name'].value = '';
                    this.$refs['marketplace-link'].value = '';
                }).catch(err => {
                    if(err){
                        console.error(err);
                        window.alert("An error has occurred.");
                    }
                });
            },

            requestAddToMarketplace(ev){
                if(ev.keyCode == 13)
                    this.addToMarketplace();
            },

            deleteFromMarketplace(index){
                fetch_controller.postJson('/marketplace/delete_partner', { index: index }, data => {
                    this.marketplace.names = new Algorithm.DeleteAlgorithm(this.marketplace.names).getProduct(index);
                    this.marketplace.links = new Algorithm.DeleteAlgorithm(this.marketplace.links).getProduct(index);
                });
            },

            getMarketplacePartners(){
                fetch(`${PORTS.SERVER}/marketplace/get_partners`)
                .then(res => res.json())
                .then(data => {
                    for(let i = 0; i < this.marketplace.names.length; i++){
                        console.log("Marketplace partner:");
                        console.log("Name: " + this.marketplace.names[i]);
                        console.log("Link: " + data.marketplace.links[i]);
                    }
                    this.marketplace.names = data.marketplace.names;
                    this.marketplace.links = data.marketplace.links;
                }).catch(err => {
                    if(err){
                        console.error(err);
                        window.alert("An error has occurred.");
                    }
                });
            },

            async requestItemModification(){
                let catKey = localStorage.getItem('edit-item-category-key');
                let catIndex = localStorage.getItem('edit-item-category-index');
                let indexAt = localStorage.getItem('edit-item-place-at');

                if(catKey && (catIndex || indexAt)){
                    this.$refs['shop-inner'].toggle();

                    fetch_controller.getJson('/item/itemCategoryKeys', async comparison => {
                        for(let i = 0; i < comparison.itemCategoryKeys.length; i++)
                            if(catKey == comparison.itemCategoryKeys[i]){
                                this.itemCategoryIndexSelected = i;
                                break;
                            }
                    });
                }

                if(catKey && catIndex){
                    this.$refs['shop-inner'].toggle();
                    this.productEditMode = true;

                    fetch_controller.getJson(`/item/info/${catKey}/${catIndex}`, data => {
                        fetch_controller.getJson('/item/itemCategoryKeys', async comparison => {
                            for(let i = 0; i < comparison.itemCategoryKeys.length; i++)
                                if(catKey == comparison.itemCategoryKeys[i]){
                                    this.itemCategoryIndexSelected = i;
                                    break;
                                }

                            await this.$nextTick();

                            this.$refs['product-name'].value = data.itemInfo.name;
                            this.$refs['product-about'].value = data.itemInfo.about;
                            if(data.itemInfo.price){
                                document.getElementById('product-price').value = data.itemInfo.price;
                                document.getElementById('product-width').value = data.itemInfo.width;
                                document.getElementById('product-height').value = data.itemInfo.height;
                                document.getElementById('product-length').value = data.itemInfo.length;
                                document.getElementById('product-weight').value = data.itemInfo.weight;
                            }
                        });
                    });

                    fetch_controller.getJson(`/item/image_lengths/${catKey}/${catIndex}`, async data => {
                        let loadImage = (itemIndex, sectionRoute) => {
                            return new Promise((resolve, reject) => {
                                fetch_controller.getBlob(`/item/${sectionRoute}/${catKey}/${catIndex}/${itemIndex}`, blob => {
                                    let fr = new FileReader();
                                    fr.addEventListener("load", ev => resolve(ev.target.result));
                                    fr.addEventListener("error", () => reject());
                                    fr.readAsDataURL(blob);
                                });
                            });
                        };

                        for(let i = 0; i < data.beforeLength; i++){
                            let url = await loadImage(i, "beforeImage");
                            this.displayImages.beforeURL.push(url);
                        }
                        for(let i = 0; i < data.afterLength; i++){
                            let url = await loadImage(i, "afterImage");
                            this.displayImages.afterURL.push(url);
                        }
                        for(let i = 0; i < data.afterLength; i++){
                            this.$refs[`display-inner-${i}`].shovePath(this.displayImages.afterURL[i]);
                            this.toggleThumbnailContext(i);
                        }
                    });
                }
            },

            redirectHome(){
                window.location.href = PORTS.CLIENT;
            }
        },

        mounted(){
            if(screen.availWidth < 1025)
                window.alert("This page should only be accessed through a desktop computer.");

            let loginId = localStorage.getItem("loginId");
            if(loginId)
                wait.millis(250, () => fetch(`${PORTS.SERVER}/dashboard/authentication/${loginId}`)
                    .then(res => res.json())
                    .then(data => {
                        this.adminLogged = data.authentic;
                        this.loginState = 1;
                        this.getMarketplacePartners();
                        this.getSubscribersList();
                        this.getItemCategoriesList();
                        this.requestItemModification();
                    }).catch(err => {
                        if(err){
                            this.loginState = 2;
                            window.location.href = `${PORTS.CLIENT}/login`;
                        }
                    }));

            //image drop box hover event
            for(let i = 0; i < 4; i++){
                this.$refs[`display-${i}`].addHoverExtension(i => {
                    for(let j = 0; j < 4; j++){
                        if(j != i)
                            this.$refs[`display-${j}`].toggle();
                    }
                });
            }
            window.addEventListener("dragover", this.dropImageWindow, false);
            window.addEventListener("drop", this.dropImage);
        },

        beforeUnmount(){
            window.removeEventListener("dragover", this.dropImageWindow, false);
            window.removeEventListener("drop", this.dropImage);

            localStorage.removeItem('edit-item-category-key');
            localStorage.removeItem('edit-item-category-index');
            localStorage.removeItem('edit-item-place-at');
        }
    }
</script>